import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { registerComponents } from '@/builder';
import Loading from '@/components/loading';
import { createStaticPaths, createStaticProps } from '@/utils/Builder';

registerComponents();

const Page = dynamic(() => import('@/modules/pages/page'));
const Article = dynamic(() => import('@/modules/pages/article'));
const NotFound = dynamic(() => import('@/components/not-found/not-found'));

export async function getStaticPaths() {
    return createStaticPaths('', false);
}

export async function getStaticProps({ params }: GetStaticPropsContext<any>) {
    return createStaticProps({ params, includeUnpublished: false });
}

const PageRoot: FC<InferGetStaticPropsType<typeof getStaticProps>> = (props) => {
    const { page, model, upNextArticle, relatedArticles } = props;
    const router = useRouter();

    if (router.isFallback) {
        return <Loading />;
    }

    if (model === 'page') {
        return <Page page={page} />;
    }

    if (model === 'article') {
        return <Article upNextArticle={upNextArticle} relatedArticles={relatedArticles} page={page} />;
    }

    return <NotFound />;
};

export default PageRoot;
